// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-grupy-regulamin-index-tsx": () => import("./../../../src/pages/grupy/regulamin/index.tsx" /* webpackChunkName: "component---src-pages-grupy-regulamin-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-o-projekcie-index-tsx": () => import("./../../../src/pages/o-projekcie/index.tsx" /* webpackChunkName: "component---src-pages-o-projekcie-index-tsx" */),
  "component---src-pages-redakcja-index-tsx": () => import("./../../../src/pages/redakcja/index.tsx" /* webpackChunkName: "component---src-pages-redakcja-index-tsx" */),
  "component---src-pages-sklep-odbierz-zamowienie-index-tsx": () => import("./../../../src/pages/sklep/odbierz-zamowienie/index.tsx" /* webpackChunkName: "component---src-pages-sklep-odbierz-zamowienie-index-tsx" */),
  "component---src-pages-szkola-rodzenia-z-anna-nowak-duda-index-tsx": () => import("./../../../src/pages/szkola-rodzenia-z-anna-nowak-duda/index.tsx" /* webpackChunkName: "component---src-pages-szkola-rodzenia-z-anna-nowak-duda-index-tsx" */),
  "component---src-pages-szkola-rodzenia-z-anna-nowak-duda-lekcja-demo-index-tsx": () => import("./../../../src/pages/szkola-rodzenia-z-anna-nowak-duda/lekcja-demo/index.tsx" /* webpackChunkName: "component---src-pages-szkola-rodzenia-z-anna-nowak-duda-lekcja-demo-index-tsx" */),
  "component---src-pages-szkola-rodzenia-z-anna-nowak-duda-polozna-na-medal-index-tsx": () => import("./../../../src/pages/szkola-rodzenia-z-anna-nowak-duda/polozna-na-medal/index.tsx" /* webpackChunkName: "component---src-pages-szkola-rodzenia-z-anna-nowak-duda-polozna-na-medal-index-tsx" */),
  "component---src-pages-szkola-rodzenia-z-anna-nowak-duda-wspieramy-nastoletnie-mamy-index-tsx": () => import("./../../../src/pages/szkola-rodzenia-z-anna-nowak-duda/wspieramy-nastoletnie-mamy/index.tsx" /* webpackChunkName: "component---src-pages-szkola-rodzenia-z-anna-nowak-duda-wspieramy-nastoletnie-mamy-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */)
}

