exports.onClientEntry = () => {
  // warehouse events
  const script = document.createElement("script")
  script.async = true
  script.src = "https://prod-warehouse.paas.mikolajczuk.in/event.js"
  document.getElementsByTagName("head")[0].appendChild(script)

  /* eslint-disable */
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = "2.0"
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/pl_PL/fbevents.js"
  )
  fbq("init", "1848330248688199")
  fbq("track", "PageView")
  ;(function (e, a) {
    var t,
      r = e.getElementsByTagName("head")[0],
      c = e.location.protocol
    t = e.createElement("script")
    t.type = "text/javascript"
    t.charset = "utf-8"
    t.async = !0
    t.defer = !0
    t.src = c + "//front.optimonk.com/public/" + a + "/js/preload.js"
    r.appendChild(t)
  })(document, "132894")

  /* Hotjar Tracking Code for https://naszepierwszedziecko.pl */
  ;(function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid: 1410520, hjsv: 6 }
    a = o.getElementsByTagName("head")[0]
    r = o.createElement("script")
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")
  /* eslint-enable */
}

exports.onRouteUpdate = function () {
  // Don't track while developing.
  /* eslint-disable */
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    fbq("track", "ViewContent")
  }
  /* eslint-enable */
}
